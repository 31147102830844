<template>
  <div class="bw-matching-wrap">
    <div style="display: flex; width: 1280px">
      <div style="width: 25%">
        <BizMatchingMenu
            :selected-support-realm="selectedSupportRealm"
            :support-realm-list="supportRealmList"
            @click="supportRealm => this.selectedSupportRealm=supportRealm"
        />
      </div>
      <div style="width: 75%; position: relative; padding-bottom: 10px">
        <!-- Cover -->
        <div class="bizmatching-cover"
             :class="{'cover-remove':userInfoComplete,'click-able':lackOfUserInfo}"
             @click="e => {if(lackOfUserInfo){mixFnSupport()}}">
          매칭정보 입력하시고<br/>맞춤형 지원사업<br/>추천을 받아보세요
        </div>
        <!-- Pie Graph And Item List -->
        <BizMatchingGraph
            :selected-support-realm="selectedSupportRealm"
            :support-realm-list="supportRealmList"
            :userInfo="userInfo"
        />
      </div>
    </div>
  </div>
</template>
<script>
import serviceMix from "../../mixins/partner/serviceMix";
import BizMatchingMenu from "./BizMatchingMenu";
import BizMatchingGraph from "./BizMatchingGraph";
const legendColors = ['#00bd9f', '#01a9ff', '#ffb841', '#ff5a46', '#785fff','#f28b8c', '#989486', '#526f7d'];
export default {
  mixins:[serviceMix],
  components: {BizMatchingMenu, BizMatchingGraph},
  data(){
    return {
      userInfo: {
        loggedIn: false,
        region: null,
        supportRealm: null,
        supportTarget: null,
      },
      selectedSupportRealm: null,
      supportRealmList: null,
    }
  },
  computed: {
    userStoreMyComp(){
      return this.$store.getters["userStore/getMyComp"];
    },
    lackOfUserInfo(){
      return !this.userInfo.region
             || !this.userInfo.supportRealm
             || !this.userInfo.supportTarget
             && this.userInfo.loggedIn;
    },
    userInfoComplete(){
      return this.userInfo.region
             && this.userInfo.supportRealm
             && this.userInfo.supportTarget
             && this.userInfo.loggedIn;
    }
  },
  created() {
    //
    this.initUserInfo();
    // # 지원분야 목록 초기화
    if ( !this.supportRealmList ) {
      this.initSupportRealm();
    }
  },
  methods: {
    initUserInfo(){
      //
      this.userInfo.loggedIn = false;
      this.userInfo.region = null;
      this.userInfo.supportRealm = null;
      this.userInfo.supportTarget = null;
      //
      const myComp = this.userStoreMyComp;
      if ( myComp ) {
        //
        this.userInfo.loggedIn = true;
        //
        const korAddr = myComp.extData?.korAddr;
        const interests = myComp.extData?.interests;
        const compTypes = myComp.extData?.compTypes;
        if ( korAddr && interests && compTypes ) {
          this.userInfo.region = this.$store.state.infoyouStore.regions.find(_region => korAddr.indexOf(_region)>=0);
          this.userInfo.supportRealm = interests;
          this.userInfo.supportTarget = compTypes;
        }
      }
    },
    initSupportRealm(){
      this.$store.dispatch('infoyouStore/fetchBizInfoCodes')
        .then(codeList => {
          if ( codeList ) {
            //
            this.supportRealmList = [];
            //
            for(let i=0; i<codeList.length; i++){
              // 기본
              const _supportRealm = {
                name: codeList[i].codeName,
                value: codeList[i].code,
                color: legendColors[i],
              }
              // 하위 코드가 있는 경우
              // if ( Array.isArray(codeList[i].sub) && codeList[i].sub.length>=1 ) {
              //   _supportRealm.value = codeList[i].sub[0];
              // }
              this.supportRealmList.push(_supportRealm);
            }
            // # 선택된 지원분야 초기화
            this.selectedSupportRealm = this.supportRealmList[0];
          }
        });
    },
  },
  watch:{
    userStoreMyComp(nv){
      this.initUserInfo();
    }
  }
}
</script>
<style scoped>
.bw-matching-wrap{
  display: flex;
  justify-content: center;
  padding-top: 67px;
  padding-bottom: 67px;
  background: #C6E0FC 0% 0% no-repeat padding-box;
}
.bizmatching-cover{
  position: absolute;
  left: 0px;
  top:0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  backdrop-filter: blur(5px);
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: 800;
  flex-direction: column;
}
.bizmatching-cover::before{
  background: url(https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/ifs/img/common/lock.svg?v=02) no-repeat 50% 50%;
  -webkit-background-size: 38px auto;
  background-size: 38px auto;
  content: "";
  display: block;
  height: 48px;
  image-rendering: -webkit-optimize-contrast;
  position: relative;
  width: 38px;
}
.bizmatching-cover.cover-remove{
  display: none;
}
.bizmatching-cover.click-able{
  cursor: pointer;
}
</style>
