<template>
  <div id="bm-menu">
    <h2>우리 회사에<br/> 꼭 필요한<br/><span><b>지원사업</b></span> 매칭</h2>
    <menu v-if="supportRealmList">
      <li v-for="(supportRealm, idx) in supportRealmList" :key="idx">
        <button
            :class="{'on':fnCheckOn(supportRealm)}"
            @click="fnClick(supportRealm)">
          {{ supportRealm.name }}
        </button>
      </li>
    </menu>
  </div>
</template>
<script>
export default {
  props: {
    selectedSupportRealm: {
      type: Object,
    },
    supportRealmList: {
      type: Array,
    }
  },
  methods: {
    fnCheckOn(_supportRealm) {
      if ( this.selectedSupportRealm && _supportRealm ) {
        return this.selectedSupportRealm.value == _supportRealm.value;
      }
    },
    fnClick(_supportRealm){
      this.$emit('click', _supportRealm);
    }
  }
}
</script>
<style scoped>
#bm-menu {
  width: 235px;
}
#bm-menu h2{
  font-size: 35px;
  letter-spacing: 0px;
  color: #09162F;
  text-shadow: 0px 3px 6px #00000029;
  border-bottom: 1px solid #09162F;
  padding-bottom: 25px;
  position: relative;
}
#bm-menu h2 span{
  position: relative;
  display: inline-block;
}
#bm-menu h2 span::before{
  content: "";
  background-color: #FCC135;
  width: 105%;
  height: 25px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
#bm-menu h2 span b{
position: relative;
z-index: 2;
}
#bm-menu menu{
  padding-left: 0px;
  width: 80%;
}
#bm-menu menu li{
  margin-bottom: 5px;
}
#bm-menu menu button{
  padding: 12px 20px;
  color: #09162F;
  font-size: 16px;
  text-align: left;
  width: 100%;
  border-radius: 20px;
}
#bm-menu menu button.on{
  box-shadow: 0px 3px 10px #00000026;
  background: #FCC135 0% 0% no-repeat padding-box;
}
</style>
