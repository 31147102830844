<template>
  <div class="sub-item">
    <img :src="'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/'+img" />
    <h4 :class="h4Cls" v-html="h4">-</h4>
    <p :class='pCls' v-html="p">-</p>
  </div>
</template>

<script>
export default {
  props:['img', 'h4', 'h4Cls', 'p', 'pCls']
}
</script>
<style scoped>
.sub-item{
  padding: 20px 10px;
  box-shadow: 0px 0px 20px #0000004D;
  border: 1px solid #D2E0F7;
  border-radius: 30px;
  background-color: white;
  /*min-width: 156px;*/
  text-align: center;
  width: 100%;
}
.c-black{
  color:#3C3C3C;
}
.a-center{
  text-align: center;
}
img{
  margin-bottom: 10px;
}
h4{
  color: #3497FD;
  font-size: 18px;
  line-height: 20px;
  height: 40px;
  margin-bottom: 10px;
}
p{
  text-align: left;
}
</style>
