<template>
  <div class="container ifs_main">
    <div id="main-wrap">
      <section id="main-bg">
        <div class="contents-wrap bw-f-r">
          <article>
            <h2>BizWise</h2>
            <figure>
              <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/img/meeting-01.png" />
            </figure>
          </article>
          <article>
            <h3><b>비즈와이즈</b>는 온라인<br/> 경영관리 플랫폼을 제공합니다.</h3>
            <p>세금환급과 4대보험료 절감을 통하여 기업의 비용을 절약하고<br/>
              기업대출 및 고용지원금 확보를 통하여 운영자금을 확보하며<br/>
              노무관리를 통하여 편리한 운영환경을 제공하는 플랫폼 입니다.</p>
          </article>
        </div>
      </section>
      <section class="bw-service">
        <ServiceTitle title="세금환급" desc="기업의 불필요한 세금을 줄여서 비용을 절약합니다." />
        <div class="bg bg-gra-left">
          <ItemBox img="tax_01.png"
                   h4="대표님 환급금 찾아가세요"
                   p="지금 바로 예상환급액을 조회해보세요"
                   button="예상 환급액 무료조회">
            3분만에 환급금액 확인 가능!<br/><b>세금환급 플랫폼</b> 다된다
          </ItemBox>
          <article>
            <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/direct-3.png">
            <div class="sub-item-wrap">
              <SubItem img="icon01.png"
                       h4="국세청 권장사업!"
                       p="[국세 기본법 제45조의 1]<br/>
                          에 의거한 제도로 5년간 과<br/>
                          ·오납한 세금을 돌려받는<br/>
                           세금 환급 제도입니다."
              />
              <SubItem img="icon02.png"
                       h4="5년이 지나면 소멸"
                       p="법정 신고기한 5년 이내 신<br/>
                       고한 세금에서 환급이 가능<br/>
                       하기 때문에 기간이 지난<br/>
                        환급액은 소멸하게 됩니다."
               />
              <SubItem img="icon03.png"
                       h4="매년 신설되는 제도<br/>수시로 변경되는 세법"
                       p="매년 조세 지원과 혜택을<br/>
                       변경 적용하여 개인이 확인<br/>
                       하는 것은 매우 어렵습니다."
              />
              <SubItem img="icon04.png"
                       h4="기본보수료 NO!"
                       p="다된다는 기본 보수료가 없으<br/>
                       며, 세금 환급 정산 전 미리<br/>
                       수수료를 요구하지 않습니다"
              />
            </div>
          </article>
        </div>
      </section>
      <section class="bw-service">
        <ServiceTitle title="4대보험료절감" desc="기업의 불필요한 세금을 줄여서 비용을 절약합니다." />
        <div class="bg bg-gra-right">
          <article>
            <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/direct-2.png" />
            <div class="sub-item-wrap">
              <BalloonBox h3="회사는 4대보험료 절감<br/>노무제도 정비로"
                          h4="비용절감 &<br/>노무리스크 정비"
                          img="icon11.png" />
              <BalloonBox h3="근로자는 4대보험료 절감<br/>소득세 절감으로"
                          h4="실수령액 증가 &<br/>급여인상 효과"
                          img="icon12.png" />
              <BalloonBox h3="최신 노동법과<br/>세법을 모두 고려한"
                          h4="실시간 4대보험료 절감<br/>실시간 4대보험료 절감"
                          img="icon13.png" />
            </div>
          </article>
          <ItemBox cls="t1"
                   img="calc_01.png"
                   p="실시간 4대보험료 절감 예상결과 무료확인하세요"
                   button="4대보험료 절감액 조회">
            <b>기업 운영의 고정비용</b> 4대보험료<br/><b>다된다가</b> 최대 연12%까지<br/>절감<b>해드릴게요!</b>
          </ItemBox>
        </div>
      </section>
      <section class="bw-service">
        <ServiceTitle title="고용지원금" desc="기업의 불필요한 세금을 줄여서 비용을 절약합니다." />
        <div class="bg bg-gra-left">
          <ItemBox img="people_01.png"
                   h4="대표님 환급금 찾아가세요"
                   p="지금 바로 예상환급액을 조회해보세요"
                   button="예상 환급액 무료조회">
            3분만에 환급금액 확인 가능!<br/><b>세금환급 플랫폼</b> 다된다
          </ItemBox>
          <article>
            <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/direct-1.png" />
            <div class="sub-item-wrap">
              <SubItem img="icon01.png"
                       h4="국세청 권장사업!"
                       p="[국세 기본법 제45조의 1]<br/>
                          에 의거한 제도로 5년간 과<br/>
                          ·오납한 세금을 돌려받는<br/>
                           세금 환급 제도입니다."
              />
              <SubItem img="icon02.png"
                       h4="5년이 지나면 소멸"
                       p="법정 신고기한 5년 이내 신<br/>
                       고한 세금에서 환급이 가능<br/>
                       하기 때문에 기간이 지난<br/>
                        환급액은 소멸하게 됩니다."
              />
              <SubItem img="icon03.png"
                       h4="매년 신설되는 제도<br/>수시로 변경되는 세법"
                       p="매년 조세 지원과 혜택을<br/>
                       변경 적용하여 개인이 확인<br/>
                       하는 것은 매우 어렵습니다."
              />
              <SubItem img="icon04.png"
                       h4="기본보수료 NO!"
                       p="다된다는 기본 보수료가 없으<br/>
                       며, 세금 환급 정산 전 미리<br/>
                       수수료를 요구하지 않습니다"
              />
            </div>
          </article>
        </div>
      </section>
      <section class="bw-service" style="margin-bottom: 100px">
        <ServiceTitle title="온라인 노무관리" desc="기업의 불필요 세금을 줄여서 비용을 절약합니다." />
        <div class="bg bg-gra-right">
          <article>
            <img src="https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/direct-3.png">
            <div class="sub-item-wrap">
              <SubItem img="1.png"
                       :h4-cls="['c-black']"
                       h4="노사간 급여분쟁 예방"
                       :p-cls="['a-center']"
                       p="노무규정과 근로자의<br/>
                       급여체계 등을 자동 분석해<br/>
                       법규 위반과<br/>
                       노사간 급여분쟁을 예방"
              />
              <SubItem img="2.png"
                       :h4-cls="['c-black']"
                       h4="ONE STOP 서비스"
                       :p-cls="['a-center']"
                       p="온라인으로<br/>
                       근로계약서, 급여대장,<br/>
                       임금명세서를 제출하는<br/>
                       ONE STOP 서비스"
              />
              <SubItem img="3.png"
                       :h4-cls="['c-black']"
                       h4="4대보험료 절감 효과"
                       :p-cls="['a-center']"
                       p="회사와 근로자 모두<br/>
                       연평균 10~12%<br/>
                       4대보험료 절감 효과"
              />
            </div>
          </article>
          <ItemBox cls="t2"
                   img="people_01.png"
                   h4="대표님 간편하 이용해보세요!"
                   p="지금 바로 ONE STOP 온라인 노무서비스 이용해보세요"
                   button="온라인 노무서비스 이용하기">
            ONE STOP 서비스로 한번에 관리 가능!<br/><b>온라인 노무관리 플랫폼</b> 다된다
          </ItemBox>
        </div>
      </section>
      <BizMatching />
    </div>
  </div>
</template>

<script>
import ServiceTitle from "../components/page/ServiceTitle";
import ItemBox from "../components/page/ItemBox";
import SubItem from "../components/page/SubItem";
import BalloonBox from "../components/page/BalloonBox";
import BizMatching from "../components/page/BizMatching";
import landingIndexMix from "~/mixins/partner/landingIndexMix";
export default {
  layout:"ifs",
  mixins:[landingIndexMix],
  head: {
    meta: [
      {
        hid: "og:image",
        property: "og:image",
        content: "https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/allok/img/meta/d3-2.png",
      },
    ]
  },
  components: {
    BalloonBox,
    ItemBox,
    ServiceTitle,
    SubItem,
    BizMatching
  },
  data() {
    return {
      useCss:false,
      link:'//s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/common/css/common.css?v=2',
    };
  },
};
</script>

<!-- ifs 페이지에서만 쓰는 css -->
<style scoped>
@import "~/static/css/bizwise_main.css";
</style>
