<template>
  <div class="box-1">
    <div class="m_box" style="cursor: default;">
      <h4>신청가능 지원사업</h4> <p>{{ computedMatchCnt }}</p>
    </div>
    <div class="m_box" style="cursor: default;">
      <h4>최대(예상) 지원금</h4> <p>{{computedSupportAmt}}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    matchCnt:{
      type: Number,
    },
    supportAmt:{
      type: Number,
    }
  },
  computed: {
    computedMatchCnt(){
      return this.matchCnt
                ? this.matchCnt.toLocaleString()+'개'
                : '???'
    },
    computedSupportAmt(){
      return this.supportAmt
          ? '최대 '+this.supportAmt.toLocaleString()+'억원'
          : '???'
    }
  }
}
</script>

<style scoped>

.box-1{
  border: .5px solid #4876ef;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding: 18px 40px 19px;
  box-shadow: 0px 3px 10px #00000026;
}
.box-1 .m_box{
  width: 50%;
  box-sizing: border-box;
}
.m_box+.m_box {
  border-left: 1px solid #e1e1e1;
  padding-left: 40px;
}
.m_box h4 {
  font-size: 1rem;
  line-height: 1.21429rem;
}
.box-1 .m_box p{
  font-size: 1.42857rem;
  letter-spacing: -.6px;
  color: #4876ef;
  font-weight: 800;
  line-height: 2rem;
  margin-top: 5px;
}
</style>
