<template>
  <div id="bm-grp">
    <div class="nav-button">
      <button type="button" class="btn_prev">&nbsp;</button>
    </div>
    <div class="main-grp">
      <BizMatchingGraphChartWrap
          :support-realm-list="supportRealmList"
          :user-info="userInfo"
      />
      <div class="item-list-wrap">
        <div v-if="bizInfoList" class="item-list">
          <item v-for="(info,idx) in bizInfoList" :key="idx"
                :biz-info="info"
                :selected-support-realm="selectedSupportRealm"
          />
        </div>
        <div v-else class="item-list">
          <item v-for="n in 6" :key="`dummyItem${n}`"
                :selected-support-realm="selectedSupportRealm"
          />
        </div>
      </div>
      <div slot="pagination" class="sw_pagination"></div>
    </div>
    <div class="nav-button">
        <button type="button" class="btn_next">&nbsp;</button>
    </div>
  </div>
</template>
<script>
import BizMatchingGraphChartWrap from "./BizMatchingGraphChartWrap";
import Item from "./BizMatchingGraphItem";
import bizInfoService from "../../service/commonAllOk/bizInfoService";
export default {
  components: {
    BizMatchingGraphChartWrap, Item
  },
  props: {
    selectedSupportRealm: {
      type: Object,
    },
    supportRealmList: {
      type: Array,
    },
    userInfo: {
      type: Object,
    },
  },
  data(){
    return {
      bizInfoList: null,
      bizInfoMap: {},
    }
  },
  methods: {
    fnSearch(){
      if ( this.selectedSupportRealm && this.userInfo.region && this.userInfo.supportTarget ) {
        const payload = {
          activeYn:'Y',
          pageNo:1,
          pageSize:8,
          region: this.userInfo.region,
          sortColumn: 'NEW',
          supportRealm: this.selectedSupportRealm.value,
          supportTarget: this.userInfo.supportTarget,
        }
        bizInfoService.fetchBizInfoList(this.$axios, payload)
          .then( res=> {
            if ( res.success && res.contents) {
              this.bizInfoMap[payload.supportRealm] = res.contents.bizinfos;
              this.bizInfoList = res.contents.bizinfos;
            }
          })
          .catch( err => console.error(err) );
      }
    }
  },
  watch: {
    selectedSupportRealm(nv){
      this.fnSearch();
    },
    userInfo(nv){
      this.fnSearch();
    }
  }
}
</script>
<style scoped>
#bm-grp{
  display: flex;
  justify-content:space-between;
  padding: 5px 0px;
}
#bm-grp .nav-button{
  display: flex;
  align-items: center;
  padding: 0px 5px;
}
#bm-grp .nav-button button{
  width: 54px;
  height: 54px;
  border-radius: 27px;
  border: 1px solid #09162F;
  transition: background-position 0.2s 0s ease-in;
  fill: #09162F;
}
#bm-grp .nav-button button.btn_prev{
  background: url("https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/arrow-left.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#bm-grp .nav-button button.btn_prev:hover{
  background-position: 40% 50%;
}
#bm-grp .nav-button button.btn_next{
  background: url("https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/arrow-right.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
#bm-grp .nav-button button.btn_next:hover{
  background-position: 60% 50%;
}
#bm-grp .main-grp{
  width:80%;
  flex-grow: 1;
  padding: 0px 32px;
}
#bm-grp .item-list-wrap{
  margin-top: 15px;
  width: 100%;
  height: 320px;
  overflow-y: scroll;
}
#bm-grp .item-list{
  width:100%;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 24px;
  grid-row-gap: 15px;
}
</style>
