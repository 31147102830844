<template>
  <div class="bizMatchingItem">
    <div class="bar" :style="{backgroundColor:selectedSupportRealm?.color}"></div>
    <div style="flex-grow: 1">
      <div class="legend">{{ selectedSupportRealm?.name }}</div>
      <h3>{{bizInfo?.pblancNm}}</h3>
      <p>제공자 : {{bizInfo?.excInsttNm}}</p>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    bizInfo:{
      type: Object,
    },
    selectedSupportRealm:{
      type: Object,
    }
  }
}
</script>
<style scoped>
.bizMatchingItem{
 background-color: white;
  border-radius: 15px;
  padding: 15px 15px 15px 0px;
  height: 130px;
  position: relative;
  box-shadow: 0px 3px 10px #00000026;
  display: flex;
  column-gap: 10px;
}
.bizMatchingItem .bar{
  min-width: 20px;
  height: 100%;
  background-color: lightgray;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bizMatchingItem .legend{
  display: inline-block;
  border: 1px solid gray;
  padding: 1px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.bizMatchingItem h3{
  font-size: 1.1rem;
  line-height: 1.2rem;
  height: 2.4rem;
  margin-bottom: 13px;
}
</style>
