<template>
  <div id="chart-legend">
    <div>
      <div v-for="(item,idx) in realmList" :key="idx" class="item">
        <span :style="{backgroundColor:item.color}"></span> <b>{{item.name}}</b>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    supportRealmList: {
      type: Array,
    },
  },
  mounted() {
    this.realmList = this.supportRealmList
                    ? this.supportRealmList
                    : this.dummyRealmList;
  },
  data(){
    return{
      dummyRealmList: [
        {name:'금융', value:'', color:'#00bd9f'},
        {name:'금융', value:'', color:'#01a9ff'},
        {name:'금융', value:'', color:'#ffb841'},
        {name:'금융', value:'', color:'#ff5a46'},
        {name:'금융', value:'', color:'#785fff'},
        {name:'금융', value:'', color:'#f28b8c'},
        {name:'금융', value:'', color:'#989486'},
        {name:'금융', value:'', color:'#526f7d'},
      ],
      realmList:null,
    }
  },
  watch: {
    supportRealmList(nv){
      this.realmList = nv;
      // console.log('supportRealmList')
    }
  }
}
</script>

<style scoped>
#chart-legend{
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
#chart-legend > div {
  display: grid;
  grid-template-columns: 70px 70px;
  grid-gap: 10px;
}
#chart-legend .item{
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 2px 5px;
}
#chart-legend .item span{
  display: block;
  width: 12px;
  height: 12px;
  background-color: yellow;
  margin-right: 5px;
}

</style>
