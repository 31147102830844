<template>
  <div class="item-box" :class="[cls]">
    <img :src="'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/img/'+img" />
    <h4 v-if="h4">{{h4}}</h4>
    <h3><slot></slot></h3>
    <p>{{p}}</p>
    <button>{{button}}</button>
  </div>
</template>

<script>
export default {
  props:['cls', 'img', 'h4','p','button'],
}
</script>

<style scoped>
.item-box{
  width: 560px;
  background-color: #D2E0F7;
  padding: 30px 20px 80px 20px;
  border-radius: 30px;
  text-align: center;
}
.item-box.t1{
  background-color: #BDE0EA;
}
.item-box.t2{
  background-color: #BDE0EA;
}
img{
  margin-bottom: 30px;
}
h3{
  color: #3497FD;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 30px;
}
h3 b{
 color: #3C3C3C;
}
.t2 h3{
  color: #6BB77E;
}
h4{
  font-size: 22px;
}
p{
  font-size: 16px;
}
h4, p{
  color: #3C3C3C;
  font-weight: 400;
  margin-bottom: 30px;
}
button{
  color: white;
  font-size: 24px;
  background-color: #3497FD;
  border-radius: 5px;
  padding: 20px 0px;
  width: 80%;
}
.t2 button{
  background-color: #6BB77E;
}
</style>
