<template>
  <div class="balloon-wrap" :class="[cls]">
    <div class="balloon">
      <h3 v-html="h3">-</h3>
      <h4 v-html="h4">-</h4>
    </div>
    <img :src="'https://s3.ap-northeast-2.amazonaws.com/static.unicornranch.co.kr/bizwise/icon/'+img" />
  </div>
</template>

<script>
export default {
  props:['cls', 'img', 'h4','h3'],
}
</script>

<style scoped>
.balloon-wrap{
  text-align: center;
}
.balloon-wrap .balloon{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000004D;
  border: 1px solid #BCE0E9;
  border-radius: 30px;
  padding: 37px 35px;
  position: relative;
  margin-bottom: 15px;
}
.balloon-wrap .balloon::after{
  background-color: white;
  bottom: -19px;
  clip-path: polygon(0 0,100% 0,50% 100%);
  -webkit-clip-path: polygon(0 0,100% 0,50% 100%);
  content: "";
  display: block;
  height: 20px;
  left: calc(50% - 20px);
  position: absolute;
  width: 40px;
}
h3{
  color: #3C3C3C;
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 8px;
  border-bottom: 1px solid #3497FD80;
  margin-bottom: 8px;
}
h4{
  color: #3497FD;
  font-size: 16px;
  line-height: 18px;
}
</style>
