<template>
  <div id="chart-search">
    <h3>신청가능한 지원사업</h3>
    <div class="bx_search">
      <input type="text" placeholder="검색어를 입력해주세요." class="ipt" v-model="keyword"/>
      <button class="btn_ico b_search" @click="fnSearch">&nbsp;</button>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      keyword: null,
    }
  },
  methods: {
    fnSearch() {
      if (!this.keyword) {
        this.$fnAlert('검색어를 입력해주세요.');
        return;
      }
      this.$store.commit('infoyouStore/UPDATE', { form: {} });
      this.$router.push(`/services/support?word=${this.keyword}`);
    },
  }
}
</script>

<style scoped>
#chart-search{
  display: flex;
  justify-content: space-between;
  align-items: center
}
.bx_search{
  position: relative;
}
.bx_search .btn_ico {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%234876EF' d='m19.818 18.933-6.053-6.112a7.8 7.8 0 0 0 1.808-5.006C15.573 3.499 12.087 0 7.787 0 3.487 0 0 3.499 0 7.815c0 4.316 3.486 7.815 7.787 7.815a7.738 7.738 0 0 0 5.105-1.918l6.045 6.105a.622.622 0 0 0 .88 0 .628.628 0 0 0 0-.884ZM7.787 14.39c-3.618 0-6.551-2.944-6.551-6.575 0-3.631 2.933-6.575 6.55-6.575 3.619 0 6.552 2.944 6.552 6.575 0 3.631-2.933 6.575-6.551 6.575Z'/%3E%3C/svg%3E") no-repeat 50% 50%;
  -webkit-background-size: 20px auto;
  background-size: 20px auto;
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  width: 20px;
}
</style>
