<template>
  <div class="char-wrap">
    <div>
      <Pie :chart-data="chartData" />
    </div>
    <div style="flex-grow: 1; padding-top: 20px">
      <Search />
      <Count :match-cnt="matchCnt" :support-amt="supportAmt" />
      <Legend :supportRealmList="supportRealmList"/>
    </div>
  </div>
</template>
<script>
import Pie from "./BizMatchingGraphChartWrapPie";
import Search from "./BizMatchingGraphChartWrapSearch";
import Count from "./BizMatchingGraphChartWrapCount";
import Legend from "./BizMatchingGraphChartWrapLegend";
import bizInfoService from "../../service/commonAllOk/bizInfoService";
export default {
  components: {
    Pie,
    Count, Search, Legend
  },
  props: {
    supportRealmList: {
      type: Array,
    },
    userInfo: {
      type: Object,
    }
  },
  mounted() {
    this.fnFetchMatchData();
  },
  data(){
    return {
      matchCnt:null,
      supportAmt:null,
      supportRealmGraphData:null,
      chartData:{
        colors:null,
        series:null,
      }
    }
  },
  methods: {
    fnFetchMatchData(){
      if ( this.userInfo.region && this.supportRealmList && this.userInfo.supportTarget ) {
        const payload = {
          region: this.userInfo.region,
          supportRealm: this.supportRealmList.map( _realm => _realm.value ),
          supportTarget: this.userInfo.supportTarget
        }
        bizInfoService.fetchBizInfoMatch(this.$axios, payload)
          .then( res => {
            if ( res.success ) {
              this.matchCnt = res.contents?.matchCnt;
              this.supportAmt = res.contents?.supportAmt;
              if ( res.contents.supportRealms ) {
                let _chartSeries = [];
                let _chartColors = [];
                res.contents.supportRealms.forEach( r => {
                  //
                  _chartSeries.push({
                    name: r.supportRealmCdNm,
                    data: parseFloat(r.supportRealmRatio),
                    value: r.supportRealmCd,
                  });
                  //
                  const found = this.supportRealmList.find ( rr => rr.value == r.supportRealmCd);
                  if ( found ) {
                    _chartColors.push(found.color)
                  }
                });
                //
                this.chartData = {
                  series: _chartSeries,
                  colors: _chartColors
                }
              }
            }
          })
          .catch( err => console.error(err));
      }
      else {
        // DummyDataSetting
        if ( this.supportRealmList ) {
          this.chartData.colors = [];
          this.chartData.series = [];
          this.supportRealmList.forEach( r => {
            this.chartData.colors.push(r.color);
            this.chartData.series.push({
              name: r.name,
              data: 10,
            })
          })
        }
      }
    }
  },
  watch: {
    userInfo(nv){
      this.fnFetchMatchData();
    },
    supportRealmList(nv){
      this.fnFetchMatchData();
    }
  }
}
</script>

<style scoped>
.char-wrap{
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 3px 10px #00000026;
  border-radius: 15px;
  padding: 10px 30px 10px 10px;
}
</style>
