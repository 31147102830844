<template>
  <div class="service-header">
    <div class="head-wrap">
      <h2>{{title}}</h2>
    </div>
    <p>{{desc}}</p>
  </div>
</template>
<script>
export default {
  props: ['title', 'desc']
}
</script>
<style scoped>
.service-header{
  text-align: center;
  padding-top: 22px;
  position: relative;
  margin-bottom: 50px;
}
.service-header .head-wrap{
  background-color: #FCC135;
  position: relative;
  display: inline-block;
  padding: 0px 10px;
  height: 20px;
}
.service-header h2{
  position: relative;
  top:-24px;
  font-size: 38px;
}
.service-header p{
  position: relative;
  font-size: 18px;
  top:-13px
}

</style>
