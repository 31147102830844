<template>
  <div ref="chart" style="width: 100%; min-width: 300px"/>
</template>
<script>
export default {
  props:{
    chartData: {
      type: Object,
    }
  },
  data(){
    return {
      chart: null,
      seriesDataList: null,
      options: {
        chart: {
          width: 300,
          height: 300,
        },
        series: {
          radiusRange: {
            inner: '50%',
            outer: '100%',
          },
          dataLabels: {
            visible: true,
            pieSeriesName: {
              visible: false,
            },
          },
          selectable:true,
        },
        legend: {
          visible:false,
        },
        theme: {
          series: {
            // colors: ['RED', 'GREEN','BLUE'],
            hover: {
              lineWidth:5,
            },
            select: {
              lineWidth:5,
              restSeries: {
                areaOpacity:0.6,
              }
            }
          }
        },
      }
    }
  },
  methods: {
    drawChart(){
      // console.log('drawChart')
      if ( this.chart ) {
        this.chart.destroy();
      }
      this.chart = toastui.Chart.pieChart({
        el: this.$refs.chart,
        data: { series: this.seriesDataList,},
        options: this.options
      });
      return;
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(nv){
        // console.log('chartData', nv)
        this.seriesDataList = nv.series;
        this.options.theme.series.colors = nv.colors;
        this.drawChart();
      }
    }
  }
}
</script>

<style scoped>
.char-wrap{
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0px 3px 10px #00000026;
  border-radius: 15px;
  padding: 10px 30px 10px 10px;
}
.box-1{
  border: .5px solid #4876ef;
  border-radius: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding: 18px 40px 19px;
}
.box-1 .m_box{
  width: 50%;
  box-sizing: border-box;
}
.m_box+.m_box {
  border-left: 1px solid #e1e1e1;
  padding-left: 40px;
}
.m_box h4 {
  font-size: 1rem;
  line-height: 1.21429rem;
}
.box-1 .m_box p{
  font-size: 1.42857rem;
  letter-spacing: -.6px;
  color: #4876ef;
  font-weight: 800;
  line-height: 2rem;
  margin-top: 5px;
}
.bx_search .btn_ico {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill='%234876EF' d='m19.818 18.933-6.053-6.112a7.8 7.8 0 0 0 1.808-5.006C15.573 3.499 12.087 0 7.787 0 3.487 0 0 3.499 0 7.815c0 4.316 3.486 7.815 7.787 7.815a7.738 7.738 0 0 0 5.105-1.918l6.045 6.105a.622.622 0 0 0 .88 0 .628.628 0 0 0 0-.884ZM7.787 14.39c-3.618 0-6.551-2.944-6.551-6.575 0-3.631 2.933-6.575 6.55-6.575 3.619 0 6.552 2.944 6.552 6.575 0 3.631-2.933 6.575-6.551 6.575Z'/%3E%3C/svg%3E") no-repeat 50% 50%;
  -webkit-background-size: 20px auto;
  background-size: 20px auto;
  height: 100%;
  position: absolute;
  right: 20px;
  top: 0;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
</style>
